import { colors } from 'shared/colors'
import styled from 'styled-components'

export const UserCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 1300px;
  height: 100px;
  background-color: ${colors.BLACK_A05};
  margin-top: 6px;
  border-radius: 7px;
  padding-top: 15px;

`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`

export const Display = styled.div`
  display:flex;
  flex-direction: row;
  width: 400px;
  align-items: center;
`

export const Label = styled.div`
  text-align: right;
  font-weight: bold;
  padding-right: 5px;
  width: 180px;
`

export const Text = styled.div`
  text-align: left
`
