const formatLowercase = (string = '') => {
  return string.toLowerCase()
}

const formatPhoneNumber = (number) => {
  if (!number) return ''
  const clean = number.replace(/\D/g, '')
  const match = clean.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return number
}

export {
  formatPhoneNumber,
  formatLowercase
}
