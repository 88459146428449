import { Container, Divider, List, Text } from './index.styles'
import React, { useState } from 'react'

import { API } from 'aws-amplify'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import SearchBar from './components/SearchBar'
import TeamPageLayout from 'shared/components/TeamPageLayout'
import UserItem from './components/UserItem'

function TeamAdmin () {
  const [loading, setLoading] = useState(false)
  const [userList, setUserList] = useState([])
  const [currentTeam, setCurrentTeam] = useState(null)
  const [currentOrg, setCurrentOrg] = useState(null)
  const [currentSearch, setCurrentSearch] = useState('')

  const loadTeamUsers = async (groupID, searchString) => {
    if (searchString) {
      searchUsers(searchString)
      return
    }
    setLoading(true)
    const results = await API.get('TALK_IT_API_ADMIN', `/group/${groupID}/users`)
    setUserList(results.data)
    setLoading(false)
  }

  const searchUsers = async (searchString) => {
    setLoading(true)
    const results = await API.get('TALK_IT_API_ADMIN', `/user?query=${encodeURI(searchString)}`)
    setUserList(results.data)
    setLoading(false)
  }

  const updateUser = async (groupID, userID, isTeamAdmin, isOrgAdmin) => {
    await API.put('TALK_IT_API_ADMIN', `/group/${groupID}/user/${userID}`, {
      body: {
        user_id: userID,
        group_id: groupID,
        is_group_admin: isTeamAdmin,
        is_group_parent_admin: isOrgAdmin
      }
    })
  }

  const removeUser = async (groupID, userID) => {
    await API.del('TALK_IT_API_ADMIN', `/group/${groupID}/user/${userID}`)
  }

  const teamChanged = async (team, org) => {
    if (!team && !org) {
      setCurrentTeam(null)
      setCurrentOrg(null)
      if (currentSearch) searchUsers(currentSearch)
      else setUserList([])
      return
    }

    if (!team) {
      setCurrentTeam(null)
      setCurrentOrg(org)
      if (currentSearch) searchUsers(currentSearch)
      else await loadTeamUsers(org.id, currentSearch)
      return
    }

    setCurrentTeam(team)
    setCurrentOrg(org)
    await loadTeamUsers(team.id, currentSearch)
  }

  const userSearch = async (searchString) => {
    if (!searchString) {
      if (currentTeam) {
        loadTeamUsers(currentTeam.id, searchString)
      } else {
        setUserList([])
      }
      setCurrentSearch('')
      return
    }
    setCurrentSearch(searchString)
    await searchUsers(searchString)
  }

  const addUserToCurrentTeam = (userID) => {
    updateUser(currentTeam.id, userID, false, false)
  }

  const removeUserFromCurrentTeam = (userID) => {
    removeUser(currentTeam.id, userID)
  }

  const adminChange = (user, isTeamAdmin, isOrgAdmin) => {
    updateUser(user.team_id, user.id, isTeamAdmin, isOrgAdmin)
  }

  const getItems = () => {
    if (userList.length === 0) {
      if (currentTeam && !currentSearch) {
        return <Text>No users in the selected team. Search for users to add them.</Text>
      } else if (!currentTeam) {
        return <Text>Select a team or search to view users.</Text>
      }
    }
    return <div>{userList.map((x) => <UserItem
      currentOrg={currentOrg}
      currentTeam={currentTeam}
      key={x.id}
      onAdd={addUserToCurrentTeam}
      onAdminChange={adminChange}
      onRemove={removeUserFromCurrentTeam}
      user={x} />)}</div>
  }

  return (
    <TeamPageLayout>
      <SearchBar onTeamChange={teamChanged} onUserSearch={userSearch} />
      <Divider/>
      <Container>
        <List>
          {loading
            ? <LoadingSpinner></LoadingSpinner>
            : getItems()
          }
        </List>
      </Container>
    </TeamPageLayout>
  )
}

export default TeamAdmin
