const events = Object.freeze({
  USER_LOGGED_IN: 'User logged in',
  USER_LOG_IN_FAILED: 'User log in failed',
  USER_ADMIN_LOG_IN_FAILED: 'A non admin user attempted to log in',
  USER_RESET_PASSWORD: 'User reset password',
  USER_SIGNED_OUT: 'User signed out',

  NAVIGATED_TO_FORGOT_PASSWORD: 'Navigated to Forgot Password View',
  NAVIGATED_TO_ACCOUNT: 'Navigated to Account View',
  NAVIGATED_TO_TEAM_ADMIN: 'Navigated to Team Admin View',

  EMAIL_APP_OPENED_FROM_VERIFY: 'Opened email app from verify email screen'
})

export default events
