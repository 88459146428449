import { Redirect, Route, Switch } from 'react-router-dom'
import { mdiAccountCircle, mdiAccountMultiple } from '@mdi/js'

import AccountOverview from 'account/AccountOverview'
import ForgotPassword from 'account/ForgotPassword'
import TeamAdmin from 'teams/TeamAdmin'
import React from 'react'
import SetPassword from 'account/SetPassword'
import SignIn from 'account/SignIn'
import events from './events'
import { getTalkItUser } from './extractors'
import { log } from './logger'
import { useAuthContext } from './context/AuthProvider'

const PUBLIC_ROUTES_CONFIG = [
  { path: '/', exact: true, component: (props) => <Redirect to={{ pathname: '/signin', search: props.location.search }} /> },
  { path: '/signin', component: SignIn },
  { path: '/forgotpassword', component: ForgotPassword, event: events.NAVIGATED_TO_FORGOT_PASSWORD },
  { path: '/setpassword', component: SetPassword }
]

const STANDARD_PRIVATE_ROUTES_CONFIG = [
  { path: '/', exact: true, component: () => <Redirect to="/account" />, event: events.NAVIGATED_TO_NOTES },
  { path: '/account', exact: true, component: () => <Redirect to="/account/overview" /> },
  { path: '/account/overview', component: AccountOverview, event: events.NAVIGATED_TO_ACCOUNT },
  { path: '/setpassword', component: SetPassword },
  { path: '/teamadmin', component: TeamAdmin, event: events.NAVIGATED_TO_TEAM_ADMIN }
]

const SIDEBAR_NAVIGATION_CONFIG = [
  { to: '/account', title: 'Account', icon: mdiAccountCircle },
  { to: '/teamadmin', title: 'Team Admin', icon: mdiAccountMultiple }
]

const STANDARD_ACCOUNT_NAVIGATION_CONFIG = [
  { to: '/account/overview', title: 'Overview & Security' }
]

function RouteWithSubRoutes (route) {
  if (route.event) {
    log(route.event)
  }

  return (
    <Route
      component={route.component}
      exact={route.exact}
      path={route.path}
    />
  )
}

function PublicRoutesForApp () {
  const routes = getRoutesWith404(PUBLIC_ROUTES_CONFIG)

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  )
}

function PrivateRoutesForApp () {
  const { currentUser } = useAuthContext()
  const { isInactive } = getTalkItUser(currentUser)
  const routes = getPrivateRoutes(isInactive)

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  )
}

function getPrivateRoutes () {
  return getRoutesWith404(STANDARD_PRIVATE_ROUTES_CONFIG)
}

function getRoutesWith404 (routes) {
  return [...routes, { component: () => <Redirect to="/" /> }]
}

function getAccountRoutes () {
  return STANDARD_ACCOUNT_NAVIGATION_CONFIG
}

export {
  PrivateRoutesForApp,
  PublicRoutesForApp,
  getAccountRoutes,
  SIDEBAR_NAVIGATION_CONFIG,
  STANDARD_ACCOUNT_NAVIGATION_CONFIG
}
