import styled from 'styled-components'
import { media } from 'shared/breakpoints'
import { colors } from 'shared/colors'

export const SignInContainer = styled.div`
    font-family: 'Nunito Sans', sans-serif;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 1rem;
    color: ${colors.BLACK};

    ${media.phone} {
        justify-content: space-between;
    }
`

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;

    svg {
        width: 85%;
        display: flex;
        align-self: center;
        margin-bottom: 3rem;
        ${media.phone} {
            margin-bottom: 2rem;
        }
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: .5rem;

    ${media.phone} {
        flex-direction: column-reverse;
        justify-content: space-between;
        flex: auto;
        max-height: 25%;
        min-height: 115px;
        a {
            width: 100%;
        }
    }
`

export const ButtonWrapper = styled.div`
    width: calc(100% - 5px);

    a, button {
        width: 100%;
        height: 100%;
    }

    ${media.phone} {
        width: 100%;
    }
`

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 1rem;

    a {
        margin: 0;
    }
`
