import { colors } from 'shared/colors'
import styled from 'styled-components'

export const Bar = styled.div`
     display: flex;
     justify-content: center;
     overflow: auto;
     background-color: ${colors.BLACK_A05};
     position: relative;
`

export const Section = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 60%;
`

export const SearchInputItem = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    width: 400px;
`
