import { Col, Display, Label, Text, UserCard } from './index.styles'
import React, { useState } from 'react'
import { mdiMinusCircle, mdiPlusCircle } from '@mdi/js'

import IconButton from '../IconButton'
import Switch from 'shared/components/Switch'
import { colors } from 'shared/colors'

const UserItem = ({ user, currentTeam, currentOrg, onAdd, onRemove, onAdminChange }) => {
  const [state, setState] = useState(user)

  const addClicked = () => {
    setState({ ...state, org_id: currentOrg.id, org_name: currentOrg.name, team_id: currentTeam.id, team_name: currentTeam.name })
    onAdd(user.id)
  }

  const removeClicked = () => {
    setState({ ...state, org_id: '', org_name: '', team_id: '', team_name: '', team_admin: false, org_admin: false })
    onRemove(user.id)
  }

  const orgAdminChange = (event) => {
    setState({ ...state, org_admin: event.currentTarget.checked })
    onAdminChange(state, state.team_admin, event.currentTarget.checked)
  }

  const teamAdminChange = (event) => {
    setState({ ...state, team_admin: event.currentTarget.checked })
    onAdminChange(state, event.currentTarget.checked, state.org_admin)
  }

  const getActionButtons = () => {
    if (!currentTeam) return (<div></div>)
    if (currentTeam.id === state.team_id) {
      return <IconButton iconPath={mdiMinusCircle} onClick={removeClicked}></IconButton>
    }
    return <IconButton iconPath={mdiPlusCircle} onClick={addClicked}></IconButton>
  }

  return (
    <UserCard style={{ backgroundColor: currentTeam?.id === state.team_id ? colors.GREEN_A05 : colors.BLACK_A05 }}>
      <Col>
        <Display>
          <Label>User ID:</Label>
          <Text>{state.id}</Text>
        </Display>
        <Display>
          <Label>Name:</Label>
          <Text>{state.name}</Text>
        </Display>
        <Display>
          <Label>Email:</Label>
          <Text>{state.email}</Text>
        </Display>
      </Col>
      <Col>
        <Display>
          <Label>Organization:</Label>
          <Text>{state.org_name || 'None'}</Text>
        </Display>
        <Display>
          <Label>Team:</Label>
          <Text>{state.team_name || 'None'}</Text>
        </Display>
      </Col>
      <Col>
        <Display>
          <Label>Organization Admin:</Label>
          <Text>{state.org_id ? <Switch checked={state.org_admin} id={`orgAdminCheck${state.id}`} onChange={orgAdminChange}></Switch> : 'N/A' }</Text>
        </Display>
        <Display>
          <Label>Team Admin:</Label>
          <Text>{state.team_id ? <Switch checked={state.team_admin} id={`teamAdminCheck${state.id}`} onChange={teamAdminChange}></Switch> : 'N/A' }</Text>
        </Display>
      </Col>
      <Col>
        {getActionButtons()}
      </Col>
    </UserCard>
  )
}

export default UserItem
