import React from 'react'
import { StyledNotification, NotificationTitle, NotificationAction, HeaderSection } from './index.styles'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'
import LoadingSpinner from '../LoadingSpinner'
import { colors } from 'shared/colors'

const Notification = ({ loading, children, ...props }) => {
  const defaultAction = <Icon color={colors.BLUE} path={mdiClose} size={1}></Icon>
  const notificationAction = props.action || defaultAction
  const { clearNotification } = useNotificationContext()

  return (
    <StyledNotification {...props}>
      <HeaderSection>
        {props.iconName && <Icon className="mainIcon" color={props.iconColor} data-testid="icon" path={props.iconName} size={1}></Icon>}
        <NotificationTitle>{children}</NotificationTitle>
        <NotificationAction data-testid="notification-primary-action" loading={`${loading}`} onClick={props.onClick || clearNotification}>
          {loading ? <LoadingSpinner withoutPadding /> : notificationAction}
        </NotificationAction>
      </HeaderSection>
    </StyledNotification>
  )
}

export default Notification
