import { StyledButton, StyledLinkButton, StyledNavLinkButton } from './index.styles'

import Icon from '@mdi/react'
import React from 'react'

const InnerButton = ({ iconName, iconColor, iconSize, children }) => {
  return (
    <React.Fragment>
      {iconName && <Icon className="mainIcon" color={iconColor} path={iconName} size={iconSize || 1}></Icon>}
      <span style={ iconName && children ? { marginLeft: '.3rem' } : { marginLeft: '0rem' }}>{children}</span>
    </React.Fragment>
  )
}

export const Button = ({ iconName, iconColor, children, iconSize, ...props }) => {
  return (
    <StyledButton {...props}>
      <InnerButton children={children} iconColor={iconColor} iconName={iconName} iconSize={iconSize} />
    </StyledButton>
  )
}

export const LinkButton = ({ iconName, iconColor, children, ...props }) => {
  return (
    <StyledLinkButton {...props}>
      <InnerButton children={children} iconColor={iconColor} iconName={iconName} />
    </StyledLinkButton>
  )
}

export const NavLinkButton = ({ iconName, iconColor, iconSize, children, ...props }) => {
  return (
    <StyledNavLinkButton {...props}>
      <InnerButton children={children} iconColor={iconColor} iconName={iconName} iconSize={iconSize} />
    </StyledNavLinkButton>
  )
}
