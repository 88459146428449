import { Display, Label } from './index.styles'
import React, { useState } from 'react'
import { isValidGroupName, isValidRequiredField } from 'shared/validators'

import { API } from 'aws-amplify'
import Input from 'shared/components/Input'
import LoadingSpinner from 'shared/components/LoadingSpinner'
import Modal from 'shared/components/Modal'
import Switch from 'shared/components/Switch'
import { text } from 'shared/text'
import { useModalContext } from 'shared/context/ModalProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'

const EditGroupModal = ({ onSave, group, parentId, title, ...props }) => {
  const { closeModal } = useModalContext()
  const { sendSuccessNotification, sendErrorNotification } = useNotificationContext()

  const initialFieldState = {
    groupName: group?.name ?? '',
    ignoreParent: group?.ignore_parent ?? false
  }
  const initialFieldErrorState = { groupName: ' ' }
  const [loading, setLoading] = useState(false)
  const [field, setField] = useState(initialFieldState)
  const [fieldError, setFieldError] = useState(initialFieldErrorState)

  const updateField = (event) => {
    validateField(event.target)
    setField({ ...field, [event.target.name]: event.target.value })
  }

  const onIgnoreChanged = (event) => {
    validateField(document.querySelector('[name=groupName]'))
    const value = !event.currentTarget.checked
    setField({ ...field, ignoreParent: value })
  }

  const validateField = (element) => {
    if (isInvalidField(element)) {
      fieldErrorValidators[element.name].forEach((requirement, index) => {
        if (!requirement.validator(element.value)) {
          setFieldError({ ...fieldError, [element.name]: fieldErrorValidators[element.name][index].error }) // eslint-disable-line security/detect-object-injection
        }
      })
    } else {
      setFieldError({ ...fieldError, [element.name]: '' })
    }
  }

  const isInvalidField = (element) => {
    return !fieldErrorValidators[element.name].every(requirement => requirement.validator(element.value))
  }

  const fieldErrorValidators = {
    groupName: [
      {
        error: 'Name is required',
        validator: isValidRequiredField
      },
      {
        error: 'Name must be less than 50 characters',
        validator: isValidGroupName
      }
    ]
  }

  const isFormValid = () => {
    const fieldErrors = Object.keys(fieldError)
    const isWithoutFieldErrors = fieldErrors.every(fieldName => fieldError[fieldName] === '') // eslint-disable-line security/detect-object-injection
    return isWithoutFieldErrors
  }

  const updateGroup = async () => {
    const groupName = field.groupName
    const ignoreParent = field.ignoreParent
    if (isFormValid()) {
      try {
        if (loading) return
        setLoading(true)

        const response = await API.put('TALK_IT_API_ADMIN', `/group`, {
          body: {
            id: group?.id || '',
            name: groupName,
            ignore_parent: ignoreParent,
            parent_id: parentId
          }
        }
        )
        triggerSuccess(text.GROUP.UPDATE.SUCCESS, response.data)
      } catch (error) {
        triggerFailure(text.GROUP.UPDATE.FAILED)
      }
    }
  }

  const triggerSuccess = (title, record = null) => {
    sendSuccessNotification({ title })
    if (onSave) onSave(record)
    closeModal()
  }

  const triggerFailure = (title) => {
    sendErrorNotification({ title })
    setLoading(false)
  }

  const modalDialogue = {
    primary: {
      onClick: updateGroup,
      disabled: !isFormValid() || loading,
      children: loading ? <LoadingSpinner /> : 'Save'
    },
    secondary: {
      disabled: loading,
      onClick: closeModal
    }
  }

  return (
    <Modal dialogue={modalDialogue} onClose={closeModal} title={title} {...props}>
      <Input
        autoComplete="off"
        data-testid="edit-group-name"
        error={fieldError.groupName}
        label="Name"
        name="groupName"
        onChange={updateField}
        type="text"
        value={field.groupName} />
      {parentId && <Display>
        <Label>Include Global Templates:</Label>
        <Switch checked={!field.ignoreParent} name="ignoreParent" onChange={onIgnoreChanged}></Switch>
      </Display>}
    </Modal>
  )
}

export default EditGroupModal
