import { colors } from 'shared/colors'
import styled from 'styled-components'

export const ToggleWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 2rem;
    border-radius: 50%;
    transition: .25s ease;
    
    :hover {
        background: ${colors.BLUE_200};
        cursor: pointer;
    }
`

export const ToggleLabel = styled.label`
    position: absolute;
    width: 3rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    background: ${colors.GREY_700};
    cursor: pointer;
    transition: all 0.2s ease;
    
    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 1.2rem;
        height: 1.2rem;
        background: ${colors.WHITE};
        transition: all 0.2s ease;
        transform: translate(0.15rem, 0.15rem);
    }
`

export const Toggle = styled.input`
    opacity: 0;
    z-index: 1;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:checked + ${ToggleLabel} {
        background: ${colors.BLUE};
        
        &::after {
            content: "";
            display: block;
            transform: translate(1.6rem, 0.15rem);
            background: ${colors.WHITE};
        }
    }
    
`
