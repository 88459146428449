const getPhoneNumberDigits = (phoneNumber) => {
  return phoneNumber.replace(/[^0-9]/g, '')
}

const getIsUserAdmin = (currentUser) => {
  const { isAdmin } = getTalkItUser(currentUser)
  return isAdmin
}

const getCognitoId = (currentUser) => {
  const { attributes: { sub: cognitoId } = {} } = currentUser || {}
  return cognitoId
}

const getCognitoEmailFromUser = (currentUser) => {
  const { attributes: { email } = {} } = currentUser || {}
  return email
}

const getDictatorIdFromUser = (currentUser) => {
  const { dictatorId } = getTalkItUser(currentUser)
  return dictatorId
}

const getFullNameFromUser = (currentUser) => {
  const { firstName, lastName } = getTalkItUser(currentUser)
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
}

const getInitialsFromUser = (currentUser) => {
  const { firstName, lastName } = getTalkItUser(currentUser)
  if (firstName && lastName) {
    return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`
  }
}

const getInitialsFromName = (name) => {
  if (isStringNullOrEmpty(name)) {
    return ''
  }
  const splitName = name.split(' ', 2)
  if (splitName.length === 2) {
    return (splitName[0][0] + splitName[1][0]).toUpperCase()
  }
  return splitName[0][0].toUpperCase()
}

const isStringNullOrEmpty = (string) => {
  if (typeof string !== 'string') {
    return true
  }
  if (!string) {
    return true
  }
  if (!string.trim()) {
    return true
  }
  return false
}

const getTalkItUser = (currentUser) => {
  const {
    talkItAttributes: {
      user: {
        first_name: firstName,
        last_name: lastName,
        billing_lines_cap_for_period: linesAvailable,
        billing_lines_used: linesUsed,
        primary_user_phone: primaryUserPhone = {},
        primary_user_email: primaryUserEmail = {},
        cognito_id: cognitoId,
        team_id: teamId,
        crm_name: crmName,
        is_trial_user: isTrialUser,
        is_inactive: isInactive,
        is_billing_manager: isAdmin,
        user_preferences: userPreferences,
        legal
      } = {}
    } = {}
  } = currentUser || {}
  const { dictator_id: dictatorId } = userPreferences || {}

  return {
    firstName,
    lastName,
    linesAvailable,
    linesUsed,
    primaryUserEmail,
    primaryUserPhone,
    cognitoId,
    teamId,
    crmName,
    dictatorId,
    isTrialUser,
    isInactive,
    isAdmin,
    userPreferences,
    legal
  }
}

const getCrmFromUser = (currentUser) => {
  const { crmName } = getTalkItUser(currentUser)
  return crmName
}

const getNameParts = (fullName) => {
  const trimmedName = fullName.trim()

  return {
    firstName: trimmedName.substr(0, trimmedName.indexOf(' ')),
    lastName: trimmedName.substr(trimmedName.indexOf(' ') + 1)
  }
}

const getEmailFromLocationState = (props) => {
  const { location: { state: { email } = {} } = {} } = props
  return email || ''
}

const getParamsFromLocationSearch = (props) => {
  const { location: { search } = {} } = props
  const rawParams = new URLSearchParams(search)
  const decodedParams = {}

  rawParams.forEach((value, key) => {
    Object.assign(decodedParams, { [key]: value })
  })

  return decodedParams
}

export {
  getNameParts,
  getPhoneNumberDigits,
  getEmailFromLocationState,
  getParamsFromLocationSearch,
  getCognitoId,
  getCognitoEmailFromUser,
  getDictatorIdFromUser,
  getFullNameFromUser,
  getInitialsFromUser,
  getInitialsFromName,
  getTalkItUser,
  getCrmFromUser,
  getIsUserAdmin
}
