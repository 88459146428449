import React from 'react'
import { getCognitoEmailFromUser, getFullNameFromUser, getInitialsFromUser, getTalkItUser } from 'shared/extractors'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useModalContext } from 'shared/context/ModalProvider'
import { LinesSection, Section, SubSection, Column, Divider } from './index.styles'
import { P2, H3, Caption, B1 } from 'shared/components/Text'
import { Button } from 'shared/components/Button'
import ButtonGroup from 'shared/components/ButtonGroup'
import { mdiExitToApp } from '@mdi/js'
import Avatar from 'shared/components/Avatar'
import Container from 'shared/components/Container'
import ChangePasswordModal from 'shared/components/ChangePasswordModal'
import EditProfileModal from 'shared/components/EditProfileModal'
import { formatPhoneNumber } from 'shared/formatters'
import { colors } from 'shared/colors'
import AccountPageLayout from 'shared/components/AccountPageLayout'

function AccountOverview () {
  const { signOut, currentUser } = useAuthContext()
  const { openModal } = useModalContext()
  const { primaryUserEmail, primaryUserPhone } = getTalkItUser(currentUser)

  return (
    <AccountPageLayout>
      <LinesSection>
        <Avatar email={getCognitoEmailFromUser(currentUser)} fullName={getFullNameFromUser(currentUser)} initials={getInitialsFromUser(currentUser)} />
      </LinesSection>
      <Section>
        <Divider/>
        <Column>
          <SubSection>
            <H3>Profile</H3>
            <Container actionButton={<Button onClick={() => openModal(<EditProfileModal />)} secondary>Edit profile</Button>} outline>
              <B1>Full Name</B1>
              <P2 style={{ marginBottom: '1rem' }}>{getFullNameFromUser(currentUser)}</P2>
              {primaryUserEmail.email_address && (
                <React.Fragment>
                  <B1>Account Email</B1>
                  <P2 style={{ marginBottom: '1rem' }}>{primaryUserEmail.email_address}</P2>
                </React.Fragment>
              )}
              {primaryUserPhone.number && (
                <React.Fragment>
                  <B1>Primary Phone</B1>
                  <P2 style={{ marginBottom: '1rem' }}>{formatPhoneNumber(primaryUserPhone.number)}</P2>
                </React.Fragment>
              )}
            </Container>
          </SubSection>
          <SubSection>
            <H3>Security</H3>
            <Caption>Manage your account security by taking action below. If you need more help, contact us.</Caption>
            <ButtonGroup width="full">
              <Button data-testid="changePassword" onClick={() => openModal(<ChangePasswordModal/>)} inverse>Change password</Button>
              <Button data-testid="signOut" iconColor={colors.BLUE} iconName={mdiExitToApp} onClick={signOut} secondary>Sign out</Button>
            </ButtonGroup>
          </SubSection>
        </Column>
      </Section>
    </AccountPageLayout>
  )
}

export default AccountOverview
