import { colors } from 'shared/colors'
import styled from 'styled-components'

export const IconStyle = styled.div`
    padding-top: 15px;
    color: ${colors.GREY_900};
    :hover {
        color: ${colors.BLUE};
        cursor: pointer;
    }
`

export const DisabledIconStyle = styled.div`
    padding-top: 15px;
    color: ${colors.GREY};
`
