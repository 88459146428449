import styled from 'styled-components'

export const Display = styled.div`
  display:flex;
  flex-direction: row;
  width: 400px;
  align-items: center;
`

export const Label = styled.div`
  text-align: right;
  font-weight: bold;
  padding-right: 5px;
  width: 250px;
`
