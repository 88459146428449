import { Bar, SearchInputItem, Section } from './index.styles'
import React, { useEffect, useState } from 'react'
import { mdiMagnify, mdiPencil, mdiPlus } from '@mdi/js'

import { API } from 'aws-amplify'
import EditGroupModal from '../EditGroupModal'
import IconButton from '../IconButton'
import Input from 'shared/components/Input'
import { useModalContext } from 'shared/context/ModalProvider'

function SearchBar ({ onTeamChange, onUserSearch }) {
  const [organizations, setOrganizations] = useState([])
  const [currentOrg, setCurrentOrg] = useState(null)
  const [teams, setTeams] = useState([])
  const [currentTeam, setCurrentTeam] = useState(null)
  const { openModal } = useModalContext()

  const searchInput = () => document.getElementsByName('search')[0]

  useEffect(() => {
    const loadOrganizations = async () => {
      const response = await API.get('TALK_IT_API_ADMIN', `/group/1/children`)
      setOrganizations(response.data)
    }

    loadOrganizations()
  }, [])

  const updateTeam = (team, org) => {
    setCurrentTeam(team)
    if (onTeamChange) onTeamChange(team, org)
  }

  const loadTeams = async (org) => {
    if (!org) {
      setTeams([])
      updateTeam(null, null)
      return
    }
    const response = await API.get('TALK_IT_API_ADMIN', `/group/${org.id}/children`)
    setTeams(response.data)
    updateTeam(null, org)
  }

  const orgChange = (event) => {
    const org = organizations.find((x) => x.id === parseInt(event.currentTarget.value))
    setCurrentOrg(org)
    loadTeams(org)
  }

  const orgSaved = (savedOrg) => {
    const org = organizations.find((x) => x.id === savedOrg.id)
    if (org) {
      org.name = savedOrg.name
      org.ignore_parent = savedOrg.ignore_parent
      setCurrentOrg(org)
    } else {
      organizations.push(savedOrg)
      setOrganizations(organizations)
      setCurrentOrg(savedOrg)
      setTeams([])
      updateTeam(null, null)
    }
  }

  const teamSaved = (savedTeam) => {
    const team = teams.find((x) => x.id === savedTeam.id)
    if (team) {
      team.name = savedTeam.name
      updateTeam(team, currentOrg)
    } else {
      teams.push(savedTeam)
      setTeams(teams)
      updateTeam(savedTeam, currentOrg)
    }
  }

  const teamChange = (event) => {
    const team = teams.find((x) => x.id === parseInt(event.currentTarget.value))
    updateTeam(team, currentOrg)
  }

  const editOrg = (event) => {
    openModal(<EditGroupModal group={currentOrg} onSave={orgSaved} parentId={1} title='Edit Organization' />)
  }

  const addOrg = (event) => {
    openModal(<EditGroupModal onSave={orgSaved} parentId={1} title='Add Organization' />)
  }

  const editTeam = (event) => {
    openModal(<EditGroupModal group={currentTeam} onSave={teamSaved} parentId={currentOrg.id} title='Edit Team' />)
  }

  const addTeam = (event) => {
    openModal(<EditGroupModal onSave={teamSaved} parentId={currentOrg.id} title='Add Team' />)
  }

  const searchUser = (event) => {
    if (onUserSearch) onUserSearch(searchInput().value)
  }

  const searchKeyUp = (event) => {
    if (!searchInput().value) searchUser(event)
    if (event.key === 'Enter') {
      searchUser(event)
    }
  }

  return (
    <Bar>
      <Section>
        <SearchInputItem>
          <Input label="Organization" onChange={orgChange} type="select" value={currentOrg?.id ?? ''} >
            <option key='0' value=''></option>
            {organizations.map((org) => <option key={org.id} value={org.id}>{org.name}</option>)}
          </Input>
          <IconButton iconPath={mdiPlus} onClick={addOrg}></IconButton>
          <IconButton disabled={!currentOrg} iconPath={mdiPencil} onClick={editOrg}></IconButton>
        </SearchInputItem>
        <SearchInputItem>
          <Input label="Team" onChange={teamChange} type="select" value={currentTeam?.id ?? ''}>
            {!currentOrg ? <option disabled={true} value=''>&larr; Select an Organization</option>
              : teams.length !== 0 ? <> <option key='0' value=''></option> {teams.map((org) => <option key={org.id} value={org.id}>{org.name}</option>)} </>
                : <option disabled={true} value=''>No Teams, try adding one. &rarr;</option> }
          </Input>
          <IconButton disabled={!currentOrg}iconPath={mdiPlus} onClick={addTeam}></IconButton>
          <IconButton disabled={!currentTeam} iconPath={mdiPencil} onClick={editTeam}></IconButton>
        </SearchInputItem>
        <SearchInputItem>
          <Input label="User" name="search" onKeyUp={searchKeyUp} />
          <IconButton iconPath={mdiMagnify} onClick={searchUser}></IconButton>
        </SearchInputItem>
      </Section>
    </Bar>
  )
}

export default SearchBar
