import { DisabledIconStyle, IconStyle } from './index.styles'

import Icon from '@mdi/react'
import React from 'react'

const IconButton = ({ onClick, iconPath, disabled, ...props }) => {
  const EnabledIcon = (onClick, iconPath) => {
    return (
      <IconStyle><Icon onClick={onClick} path={iconPath} size={1.5} /></IconStyle>
    )
  }

  const DisabledIcon = (iconPath) => {
    return (
      <DisabledIconStyle><Icon path={iconPath} size={1.5} /></DisabledIconStyle>
    )
  }

  return disabled ? DisabledIcon(iconPath) : EnabledIcon(onClick, iconPath)
}

export default IconButton
