import React, { useState } from 'react'
import Input from 'shared/components/Input'
import { ModalDescription } from '../Text/index'
import { formatPhoneNumber } from 'shared/formatters'
import { isValidFullName, isValidEmail, isValidPhoneNumber, isValidRequiredField } from 'shared/validators'
import { useAuthContext } from 'shared/context/AuthProvider'
import { useModalContext } from 'shared/context/ModalProvider'
import { useNotificationContext } from 'shared/context/NotificationProvider'
import { getFullNameFromUser, getTalkItUser, getCognitoId, getNameParts, getPhoneNumberDigits } from 'shared/extractors'
import { API } from 'aws-amplify'
import Modal from '../Modal'
import { text } from 'shared/text'
import LoadingSpinner from '../LoadingSpinner'

const EditProfileModal = (props) => {
  const { currentUser, refreshUser } = useAuthContext()
  const { closeModal } = useModalContext()
  const { sendSuccessNotification, sendErrorNotification } = useNotificationContext()
  const cognitoId = getCognitoId(currentUser)
  const { primaryUserEmail, primaryUserPhone } = getTalkItUser(currentUser)

  const initialFieldState = {
    userFullName: getFullNameFromUser(currentUser),
    userEmail: primaryUserEmail.email_address,
    userPhoneNumber: formatPhoneNumber(primaryUserPhone.number)
  }
  const initialFieldErrorState = { userFullName: '', userEmail: '', userPhoneNumber: '' }
  const [loading, setLoading] = useState(false)
  const [field, setField] = useState(initialFieldState)
  const [fieldError, setFieldError] = useState(initialFieldErrorState)

  const updateField = (event) => {
    setField({ ...field, [event.target.name]: event.target.value })
  }

  const validateFieldOnKeyUp = (event) => {
    if (fieldError[event.target.name]) {
      validateField(event)
    }
  }

  const validateField = (event) => {
    if (isInvalidField(event)) {
      fieldErrorValidators[event.target.name].forEach((requirement, index) => {
        if (!requirement.validator(event.target.value)) {
          setFieldError({ ...fieldError, [event.target.name]: fieldErrorValidators[event.target.name][index].error }) // eslint-disable-line security/detect-object-injection
        }
      })
    } else {
      setFieldError({ ...fieldError, [event.target.name]: '' })
    }
  }

  const isInvalidField = (event) => {
    return !fieldErrorValidators[event.target.name].every(requirement => requirement.validator(event.target.value))
  }

  const fieldErrorValidators = {
    userFullName: [
      {
        error: 'Invalid full name, please use your full name',
        validator: isValidFullName
      },
      {
        error: 'Required field',
        validator: isValidRequiredField
      }
    ],
    userEmail: [
      {
        error: 'Invalid email address format',
        validator: isValidEmail
      },
      {
        error: 'Required field',
        validator: isValidRequiredField
      }
    ],
    userPhoneNumber: [
      {
        error: 'Invalid phone number, must be 10 digits',
        validator: (value) => {
          if (value.length > 0) {
            return isValidPhoneNumber(value)
          }
          return true
        }
      }
    ]
  }

  const isFormValid = () => {
    const fieldErrors = Object.keys(fieldError)
    const isWithoutFieldErrors = fieldErrors.every(fieldName => fieldError[fieldName] === '') // eslint-disable-line security/detect-object-injection
    return isWithoutFieldErrors
  }

  const updateUser = async () => {
    if (isFormValid()) {
      const name = getNameParts(field.userFullName)
      const phoneNumber = getPhoneNumberDigitsOrNull(field.userPhoneNumber)

      try {
        if (loading) return
        setLoading(true)
        await API.put('TALK_IT_API', `/user/${cognitoId}`, {
          body: {
            first_name: name.firstName,
            last_name: name.lastName,
            primary_user_email: {
              email_address: field.userEmail
            },
            primary_user_phone: getPhoneNumberObjectOrUndefined(phoneNumber)
          }
        })
        await refreshUser()
        triggerSuccess(text.USER.UPDATE.SUCCESS)
      } catch (error) {
        if (error.request && error.request.status === 409) {
          triggerFailure(text.USER.UPDATE.FAILED_ALREADY_REGISTERED)
        } else if (error.request && error.request.status === 400) {
          triggerFailure(text.USER.UPDATE.EMAIL_IN_USE)
        } else {
          triggerFailure(text.USER.UPDATE.FAILED)
        }
      }
    }
  }

  const getPhoneNumberObjectOrUndefined = (phoneNumber) => {
    if (phoneNumber) {
      return {
        number: phoneNumber
      }
    }
    return undefined
  }

  const getPhoneNumberDigitsOrNull = (phoneNumber) => {
    if (phoneNumber.trim() === '') {
      return null
    }
    return `+1${getPhoneNumberDigits(phoneNumber)}`
  }

  const triggerSuccess = (title) => {
    sendSuccessNotification({ title })
    if (props.onUpdate) props.onUpdate()
    closeModal()
  }

  const triggerFailure = (title) => {
    sendErrorNotification({ title })
    setLoading(false)
  }

  const modalDialogue = {
    primary: {
      onClick: updateUser,
      disabled: !isFormValid() || loading,
      children: loading ? <LoadingSpinner /> : 'Save'
    },
    secondary: {
      disabled: loading,
      onClick: closeModal
    }
  }

  return (
    <Modal dialogue={modalDialogue} onClose={closeModal} title={'Edit Profile'} {...props}>
      <ModalDescription>Edit or update your personal information.</ModalDescription>
      <Input
        data-testid="edit-profile-full-name"
        error={fieldError.userFullName}
        label="Full Name"
        name="userFullName"
        onChange={updateField}
        onKeyUp={validateFieldOnKeyUp}
        type="text"
        value={field.userFullName} />
      <Input
        data-testid="edit-profile-email"
        error={fieldError.userEmail}
        label="Account Email"
        name="userEmail"
        onBlur={validateField}
        onChange={updateField}
        onKeyUp={validateFieldOnKeyUp}
        type="email"
        value={field.userEmail} />
      <Input
        data-testid="edit-profile-phone"
        error={fieldError.userPhoneNumber}
        label="Primary Phone"
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        name="userPhoneNumber"
        onBlur={validateField}
        onChange={updateField}
        onKeyUp={validateFieldOnKeyUp}
        type="tel"
        value={field.userPhoneNumber} />
    </Modal>
  )
}

export default EditProfileModal
