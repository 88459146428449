import { Area, Divider, Header } from './index.styles'

import { H1 } from '../Text'
import React from 'react'

function TeamPageLayout (props) {
  return (
    <Area>
      <Header>
        <H1>Team Manager</H1>
        <Divider />
      </Header>
      {props.children}
    </Area>
  )
}

export default TeamPageLayout
