import React from 'react'
import AccountTabs from '../AccountTabs'
import { H1 } from '../Text'
import MobileTabNav from '../MobileTabs'
import { Area, Header, Nav, Container } from './index.styles'

function AccountPageLayout (props) {
  return (
    <Area>
      <Header>
        <H1>Account</H1>
        <Nav>
          <AccountTabs />
          <MobileTabNav />
        </Nav>
      </Header>
      <Container>
        {props.children}
      </Container>
    </Area>
  )
}

export default AccountPageLayout
