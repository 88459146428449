import { colors } from 'shared/colors'
import { media } from 'shared/breakpoints'
import styled from 'styled-components'

export const Area = styled.div`
    position: relative; 
    height: 100%;
    overflow-y:scroll;
    color: ${colors.BLACK};

    ${media.tablet} {
        height: calc(100% - 4rem);
    }
`

export const Header = styled.div`
    h1 {
        padding: 2rem 2rem 0 2rem;
    }

    ${media.mobileNav} {
        h1 {
            margin-bottom: .5rem;
            padding: 1rem 1rem 0 1rem;
        }
    }
`

export const Divider = styled.div`
    background: ${colors.GREY_200};
    width: 100%;
    height: 2px;
    boarder-radius: 2px;
`
