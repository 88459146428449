import { colors } from 'shared/colors'
import { media } from 'shared/breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
    padding: 0 2rem 2rem;

    ${media.mobileNav} {
        padding: 0 1rem 4rem;
    }
`
export const List = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    width: 100%;
`

export const Divider = styled.div`
    background: ${colors.GREY_200};
    width: 100%;
    height: 2px;
    border-radius: 2px;
`

export const Text = styled.div`
    font-size: 1.5em;
    margin-top: 10px;
    background-color: ${colors.GREEN_A05};
    padding: 10px;
    border-radius: 6px;
`
