import styled, { css } from 'styled-components'

import MaskInput from 'react-text-mask'
import { colors } from 'shared/colors'

const sharedInput = (props) => `
    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;
    padding: 0.75rem;
    display: flex;
    outline: none;
    margin-top: .4rem;
    border-radius: 10px;
    border: 2px solid ${colors.BLACK};
    transition: all .3s ease;
    flex: 1;
    width: 100%;
    
    :hover {
        border: 2px solid ${colors.BLUE};
    }
    :focus {
        border: 2px solid ${colors.BLUE};
    }
    &::-webkit-input-placeholder {
        color: ${colors.GREY};
    }

    ${props.error && css`
        border-color: ${colors.RED};
    `}
`

export const StyledInput = styled.input`
    ${props => sharedInput(props)}
`

export const StyledMaskInput = styled(MaskInput)`
    ${props => sharedInput(props)}
`

export const StyledSelect = styled.select`
    ${props => sharedInput(props)}
`

export const Label = styled.label`
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Nunito Sans', sans-serif;
    display: flex;
    flex-direction: column;
    margin-bottom: .75rem;
    width: 100%;
`

export const Error = styled.div`
    font-size: 0.9rem;
    font-family: 'Nunito Sans',sans-serif;
    font-weight: normal;
    margin-top: 0.25rem;
    color: ${colors.RED};
`

export const ErrorContainer = styled.div`
    width: 100%;
`

export const RevealContainer = styled.div`
    display: flex;
    position: relative;
`

export const RevealAction = styled.div`
    position: absolute;
    justify-content: center;
    transform: translateY(-50%);
    top: calc(50% + 3px);
    display: flex;
    align-items: center;
    right: calc(.5rem + 2px);
    padding: .15rem;
    user-select: none;
    border-radius: 50%;
    width: calc(2rem - 2px);
    height: calc(2rem - 2px);
    transition: .25s ease;
    opacity: .5;

    svg {
        margin-bottom: 0 !important;
    }

    :hover {
        opacity: 1;
        cursor: pointer;
    }
`
