import React, { createContext, useState, useContext } from 'react'
import { mdiAlertCircleOutline, mdiCheckCircleOutline } from '@mdi/js'
import Notification from 'shared/components/Notification'
import { StyledNotificationsContainer } from 'shared/components/Notification/index.styles'
import { colors } from 'shared/colors'

export const NotificationContext = createContext(null)

export const NotificationContainer = () => {
  const { notification } = useNotificationContext()

  if (notification) {
    return (
      <StyledNotificationsContainer data-testid="notification-container">
        <Notification
          children={notification.title}
          {...notification} />
      </StyledNotificationsContainer>
    )
  }

  return null
}

const NotificationProvider = props => {
  const [notification, setNotification] = useState(null)
  const initialErrorNotification = {
    iconColor: colors.RED,
    iconName: mdiAlertCircleOutline,
    position: 'topRight',
    timeout: 6000
  }
  const initialSuccessNotification = {
    iconColor: colors.GREEN,
    iconName: mdiCheckCircleOutline,
    position: 'topRight',
    timeout: 6000
  }

  const clearNotification = () => setNotification(null)
  const sendNotification = notification => {
    setNotification(notification)

    if (notification.timeout) {
      setTimeout(() => clearNotification(), notification.timeout)
    }
  }
  const sendErrorNotification = notification => sendNotification({ ...initialErrorNotification, ...notification })
  const sendSuccessNotification = notification => sendNotification({ ...initialSuccessNotification, ...notification })

  return (
    <NotificationContext.Provider
      value={{
        notification,
        sendNotification,
        sendErrorNotification,
        sendSuccessNotification,
        clearNotification }}
      {...props} />
  )
}

export const useNotificationContext = () => useContext(NotificationContext)

export default NotificationProvider
