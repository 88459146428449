import { Toggle, ToggleLabel, ToggleWrapper } from './index.styles'

import React from 'react'

const Switch = ({ id, ...props }) => {
  return (
    <ToggleWrapper>
      <Toggle id={id} type="checkbox" {...props} />
      <ToggleLabel htmlFor={id} />
    </ToggleWrapper>
  )
}

export default Switch
